import { Injectable } from '@angular/core';
import {HttpClient} from "../../../../main/modules/http/classes";
import {HttpService} from "../../../../main/modules/http/services";
import {Observable} from "rxjs";
import {ArchiveValues, Project} from "../models/project.modal";
import {CacheKeys} from "../../../../main/constants/cache.constant";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    private httpClient: HttpClient;
    public httpOptions = {
        model: Project
    };

    constructor(public http: HttpService) {
        this.httpClient = new HttpClient(this);
    }

    public getProjects(includeArchive: boolean = false): Observable<any> {
        return this.httpClient.get('project', {isListing: true, cacheKey: CacheKeys.PROJECT_LISTS})
            .pipe(
                map((val: Project[]) => {
                    if (includeArchive) {
                        return val;
                    }
                    return val.filter(item => item.archive !== ArchiveValues.ARCHIVED);
                })
            );
    }

    public deleteProject(id: number): Observable<any> {
        return this.httpClient.delete(`project/${id}`, {removingCacheKey: CacheKeys.PROJECT_LISTS});
    }

    public updateProject(id: number, data: CreateProjectRequest): Observable<any> {
        const formData = new FormData();
        for (const key in data) {
            formData.append(key , data[key]);
        }
        formData.append('_method', 'PUT');
        return this.httpClient.post(`project/${id}`, {removingCacheKey: CacheKeys.PROJECT_LISTS}, formData);
    }

    public createProject(data: CreateProjectRequest): Observable<any> {
        const formData = new FormData();
        for (const key in data) {
            formData.append(key , data[key]);
        }
        return this.httpClient.post('project', {removingCacheKey: CacheKeys.PROJECT_LISTS}, formData);
    }
}

export class CreateProjectRequest {
    public name?: string;
    public logo?: File;
    public amount_per_month?: string;
    public bank_info?: string;
    public archive?: boolean;
    public address?: boolean;

    constructor(data: any) {
        if (data.name) {
            this.name = data.name;
        }
        this.address = data.address;
        if (data.amount) {
            this.amount_per_month = data.amount;
        }
        if (data.logo) {
            this.logo = data.logo;
        }
        if (data.bank) {
            this.bank_info = data.bank;
        }
        if (data.archive) {
            this.archive = data.archive;
        }
    }
}
