import {BaseModel} from "../../../../main/modules/http/classes";
import {Bank, BankTypes} from "../../invoices/models/bank.modal";
import {Employee} from "../../users/models/employee";
import {PAYMENTS} from "../../invoices/constants/payment-mehtods.const";
import {ProjectColors} from "../enums/project-colors.enum";

export enum ArchiveValues {
    ARCHIVED = 1,
    UNARCHIVED = 0
}

export class Project extends BaseModel {
    public id: number;
    public name: string;
    public bank: BankTypes;
    public bankLabel: string;
    public amount: number;
    public logo: string;
    public archive?: ArchiveValues;
    public invoiceNumber: number;
    public employeeAmount?: number;
    public employees: Employee[];
    public resources: any = [];
    public paymentType: PAYMENTS;
    public color: any;
    public address: string;

    constructor(data: any) {
        super();
        this.id = data.id;
        this.name = data.name;
        this.amount = data.amount_per_month;
        this.bank = data.bank_info;
        this.archive = data.archive;
        this.bankLabel = BankTypes[this.bank];
        this.logo = data.logo;
        this.invoiceNumber = data.invoice_number;
        this.paymentType = data.payment_type;
        this.employees = Employee.transformCollection(<Employee[]>(data.employee || []));
        this.employees = this.employees.sort((a, b) => a.order - b.order);
        this.color = ProjectColors[this.name];
        this.address = data.address;
    }
}
