export enum ProjectColors {
    'Swatchbook' = '#0d68e1',
    'Siemplify' = '#e83729',
    'QTX' = '#009edd',
    'Arena' = '#000000',
    'Kizen' = '#36b3f4',
    'AHE' = '#000000',
    'e-gear' = '#c03a98',
    'Fifth' = '#fff',
    'Troopz' = '#20ad23',
    'Siply' = '#f3af00',
    'Love on Paper' = '#644f60',
    'Upstart' = '#4bbb7b',
    'MyTraining' = '#ff6b11',
    'Thrive' = '#20ac82',
    'Jucari' = '#72bf44',
}
